<template>
  <div>
      <filter-slot
      :filter="[]"
      :no-visible-principal-filter="true"
      :filter-principal="{}"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refEmailList'].refresh()"
    >
      <template #table>
        <b-table
          ref="refEmailList"
          show-empty
          sticky-header
          small
          responsive="sm"
          :fields="fieldsQuicks"
          :items="getAllQuicksEmail"
          :busy.sync="isBusy"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column EMAIL -->
          <template #cell(content)="data">
            <change-sms :sms-data="data.item.content" />
          </template>
          <!-- Column CREATED BY -->
          <template #cell(user_created)="data">
            <div>
              <span>{{ data.item.user_created }}</span>
            </div>
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>
          <!-- Column UPDATED BY -->
          <template #cell(updated_at)="data">
            <div>
              <span>{{ data.item.user_updated }}</span>
            </div>
            <span v-if="data.item.updated_at != null">{{ data.item.updated_at | myGlobal }}</span>
          </template>
          <!-- Column ACTIONS -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Button EDIT   -->
              <b-dropdown-item
                variant="warning"
                @click="editQuick(data.item.id,data.item.content,data.item.subject)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <!-- Button DELETE  -->
              <b-dropdown-item
                variant="danger"
                @click="deleteQuick(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <!-- Modal Quick Email Save -->

    <b-modal
      id="modal-quick-email-save"
      title-class="h4 text-white"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="(type)==1 ? 'Edit Quick Email' : 'New Quick Email'"

      hide-footer

    >
      <modal-quick-email-save
        :quick="addquick"
        :modul="modul"
        :type="type"
        @refreshTable="$refs['refEmailList'].refresh()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// Import Service
import ClientService from '@/views/commons/components/clients/services/clients.services.js'
// Import Components
import ModalQuickEmailSave from '@/views/commons/components/clients/components/clientsEmail/ModalQuickEmailSave.vue'
import ChangeSms from '@/views/commons/components/clients/components/clientsSms/ChangeSms.vue'
import clientsServices from '@/views/commons/components/clients/services/clients.services'

export default {
  components: {
    ModalQuickEmailSave,
    ChangeSms,
  },

  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,

      modul: 23,
      quickemail: false,
      modal: false,
      gifsearch: true,
      addquick: {},
      type: '',
      isBusy: false,
      fieldsQuicks: [
        { key: 'subject', label: 'Subject' },
        { key: 'content', label: 'Email' },
        { key: 'user_created', label: 'Created by' },
        { key: 'updated_at', label: 'Updated by' },
        { key: 'action', label: 'Actions' },
      ],
      quicks: [],
    }
  },

  methods: {
    async getAllQuicksEmail(ctx) {
      try {
        const params = {
          modul: 23,
          perpage: ctx.perPage,
          page: ctx.currentPage,
        }
        const data = await clientsServices.allQuicksEmailByModulId(params)
        this.quicks = data.data.data
        this.paginate.currentPage = data.data.current_page
        this.startPage = data.data.from
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalRows = data.data.total
        this.toPage = data.data.to
        return this.quicks || []
      } catch (error) {
        console.log(error)
      }
    },

    ...mapActions('clients-store', ['REMOVE_QUICK']),
    createQuick() {
      this.type = 0
      this.addquick = { id: '', content: '', subject: '' }
      this.$bvModal.show('modal-quick-email-save')
    },
    editQuick(id, sms, title) {
      this.type = 1
      this.addquick = { id, content: sms, subject: title }
      this.$bvModal.show('modal-quick-email-save')
    },
    async deleteQuick(id) {
      const confirm = await this.showConfirmSwal()
      if (confirm.isConfirmed) {
        try {
          this.REMOVE_QUICK(id)
          const data = await ClientService.deleteQuickEmail({ id })
        this.$refs.refEmailList.refresh()
          this.showToast(
            'success',
            'top-right',
            'Success!',
            'CheckIcon',
            'Successful operation',
          )
        } catch (error) {
          console.log('Something went wrong modalQuickDelete', error)
          this.showToast(
            'danger',
            'top-right',
            'Oop!',
            'AlertOctagonIcon',
            this.getInternalErrors(error),
          )
        }
      }
    },
  },
}
</script>
